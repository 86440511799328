import {Route, Routes} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import Layout from './components/Layout';
import Table from './components/Result/Table/Table';
import {Politica} from './components/Politica/Politica';
import Oferta from './components/Oferta/Oferta';
import Calibration from './pages/Calibration/Calibration';
import Camera from './pages/Camera/Camera';
import makefemale from './assets/popups/68.jpeg'
import Eyetracker from './pages/Eyetracker/Eyetracker';
import FormRegistrate from './pages/FormRegistrate/FormRegistrate';
import ConfigurePage from './pages/ConfigurePage/ConfigurePage';
import Diagnose from './pages/Diagnose/Diagnose';
import FinalTest from './pages/FinalTest/FinalTest';
import Home from './pages/Home/Home';
import Instruction from './pages/Instruction/Instruction';
import {PartnerResult} from './components/Result/PartnerResult';
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalNoGo} from './components/popup/Popup';
import Button, {ButtonIcon} from './components/buttons/Button';
import TestResult from './components/Result/TestResult/TestResult';
import {Form} from './components/popup/Form/Form';
import copy from './assets/popups/copy.svg'
import TableId from './components/Result/Table/TableId';
import Payment from './pages/Payment/Payment';
import {useTranslation} from 'react-i18next';


function App() {
    const {t, i18n} = useTranslation();
    const DEFAULT_LANGUAGE = 'uk';
    const LANGUAGES = ['eng', 'ru'];

    const LANG_CODES = {
        eng: 'en-US',
        ru: 'ru-UA',
        uk: 'uk-UA',
    };

    const [localLanguage, setLocalLanguage] = useState(
        DEFAULT_LANGUAGE
    );

    const getLanguageFromPath = () => {
        const pathSegments = window.location.pathname.split('/');
        return pathSegments[1];
    }

    useEffect(() => {
        setCopied(t('copybutton'));

        const language = getLanguageFromPath();
        document.documentElement.lang = LANGUAGES.includes(language) ? LANG_CODES[language] : LANG_CODES[DEFAULT_LANGUAGE];
    }, [t]);

    useEffect(() => {
            const language = getLanguageFromPath();
            const isLanguageAvailable = LANGUAGES.includes(language);
            const determinedLanguage = isLanguageAvailable ? language : DEFAULT_LANGUAGE;

            if (determinedLanguage !== localLanguage) {
                setLocalLanguage(determinedLanguage);
                i18n.changeLanguage(determinedLanguage);
            }
        },
        [localLanguage, i18n]);


    const [showModal, setShowModal] = useState(false);
    const [step, setStep] = useState(2);
    const [copied, setCopied] = useState(t('copybutton'));
    const [urlParam, seturlParam] = useState('');
    const currentLang = i18n.language !== DEFAULT_LANGUAGE ? `/${i18n.language}` : '';
    const URLtoCOPY = `${process.env.REACT_APP_SITE}${currentLang}/instruction/${urlParam}`;
    const goNext = () => {
        window.location.href = URLtoCOPY;
    }
    const copyText = async () => {
        await navigator.clipboard.writeText(URLtoCOPY);
        setCopied(t('copybutton_result'));
        setTimeout(() => {
            setCopied(t('copybutton'));
        }, 4000);
    }

    const onCloseModal = () => {
        setStep(2);
    }

    return (
        <>
            <Routes>
                <Route path='/' element={<Layout showModal={showModal} setShowModal={setShowModal}/>}>
                    <Route index element={<Home showModal={showModal} setShowModal={setShowModal}/>}/>
                    <Route path='/:id' element={<Home showModal={showModal} setShowModal={setShowModal}/>}/>
                    <Route path="demo" element={<TestResult showModal={showModal} setShowModal={setShowModal}/>}/>
                    <Route path="configure" element={<ConfigurePage/>}/>
                    <Route path="registrate/:id" element={<FormRegistrate/>}/>
                    <Route path="quiz" element={<Diagnose/>}/>
                    <Route path="quiz/:id" element={<Diagnose/>}/>
                    <Route path="politica" element={<Politica/>}/>
                    <Route path="oferta" element={<Oferta/>}/>
                    <Route path="test" element={<FinalTest/>}/>
                    <Route path="instruction/:id" element={<Instruction defaultLanguage={DEFAULT_LANGUAGE}/>}/>
                    <Route path="instruction" element={<Instruction defaultLanguage={DEFAULT_LANGUAGE}/>}/>
                    <Route path="result/:id" element={<Table/>}/>
                    <Route path="end/:id" element={<TableId/>}/>
                    <Route path="finish/:id" element={<PartnerResult/>}/>
                    <Route path="calibration" element={<Calibration defaultLanguage={DEFAULT_LANGUAGE}/>}/>
                    <Route path="camera/:id" element={<Camera/>}/>
                    <Route path="tracker" element={<Eyetracker/>}/>
                    <Route path="tracker/:id" element={<Eyetracker/>}/>
                    <Route path="camera" element={<Camera defaultLanguage={DEFAULT_LANGUAGE}/>}/>
                    <Route path="payment" element={<Payment/>}/>
                </Route>

                {/* Для языков с префиксами */}
                <Route path='/:lang' element={<Layout showModal={showModal} setShowModal={setShowModal}/>}>
                    <Route index element={<Home showModal={showModal} setShowModal={setShowModal}/>}/>
                    <Route path="demo" element={<TestResult showModal={showModal} setShowModal={setShowModal}/>}/>
                    <Route path="configure" element={<ConfigurePage/>}/>
                    <Route path="registrate/:id" element={<FormRegistrate/>}/>
                    <Route path="quiz" element={<Diagnose/>}/>
                    <Route path="quiz/:id" element={<Diagnose/>}/>
                    <Route path="politica" element={<Politica/>}/>
                    <Route path="oferta" element={<Oferta/>}/>
                    <Route path="test" element={<FinalTest/>}/>
                    <Route path="instruction/:id" element={<Instruction defaultLanguage={DEFAULT_LANGUAGE}/>}/>
                    <Route path="instruction" element={<Instruction defaultLanguage={DEFAULT_LANGUAGE}/>}/>
                    <Route path="result/:id" element={<Table/>}/>
                    <Route path="end/:id" element={<TableId/>}/>
                    <Route path="finish/:id" element={<PartnerResult/>}/>
                    <Route path="calibration" element={<Calibration defaultLanguage={DEFAULT_LANGUAGE}/>}/>
                    <Route path="camera/:id" element={<Camera/>}/>
                    <Route path="tracker" element={<Eyetracker/>}/>
                    <Route path="tracker/:id" element={<Eyetracker/>}/>
                    <Route path="camera" element={<Camera defaultLanguage={DEFAULT_LANGUAGE}/>}/>
                    <Route path="payment" element={<Payment/>}/>
                </Route>
            </Routes>

            {step === 2 ? <Modal
                    show={showModal}
                    setShow={setShowModal}
                >
                    <ModalHeader>
                        <h4 style={{marginBottom: "20px"}}>{t("popup_title")}</h4>
                        <img style={{width: '140px', margin: '0 auto', borderRadius: '15px'}} src={makefemale}/>
                        <h6>{t("popup_description")}</h6>
                    </ModalHeader>
                    <ModalBody>
                        <Form thissetStep={setStep} seturlParam={seturlParam}/>

                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-violet' style={{margin: '0 auto'}}
                                form='main-form'>{t("popup_main_button")}</Button>
                    </ModalFooter>
                </Modal>
                : null}

            {step === 4 && <ModalNoGo
                show={showModal}
                setShow={setShowModal}
                onClose={onCloseModal}
            >

                <ModalHeader>
                    <h3 className='modal-h3'>{t("popup_titlefinish")}</h3>
                </ModalHeader>
                <ModalBody>
                    <p className='modal-p'>{t("popup_descriptionfinish")}</p>
                </ModalBody>
                <ModalFooter>
                    <div className='buttons__elements'>
                        <ButtonIcon className='btn-grey' style={{padding: '20px 50px'}} onClick={copyText} icon={copy}
                                    classNameIcon='icon_padding'>{copied}</ButtonIcon>
                        <Button className='btn-vilet' onClick={goNext}>{t("nextbutton")}</Button>
                    </div>
                </ModalFooter>
            </ModalNoGo>
            }
        </>
    );
}

export default App;

