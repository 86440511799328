
import React, { useState, useEffect } from "react";
import "./FinalTest.scss";
// import { Helmet } from 'react-helmet';
import { ModalNoGo, ModalBody, ModalFooter, ModalHeader } from "../../components/popup/Popup";
import Questions from "../Diagnose/questions";
import { useLocation } from 'react-router-dom';
import Button from "../../components/buttons/Button";
import axios from 'axios';
import { useTranslation } from 'react-i18next';



const FinalTest = () => {
    const { t, i18n } = useTranslation();
    const idData = useLocation();
    const id = idData.search.slice(4);
    const [loading, setloading] = useState(true);
    const [seetheResult, setseetheResult] = useState(t('get_finalResults'));
    const [showModal, setShowModal] = useState(true);
    const [activeQuestion, setActiveQuestion] = useState(0);
    const [step, setStep] = useState(2);
    const [Quiz, setQuiz] = useState([]);
    const [name, setname] = useState('');
    const [Finish, setFinish] = useState('');
    const [ourData, setourData] = useState('');
    const [Allanswers, setAllanswers] = useState([]);

    /*Before start we are taking questions from exact collection
    Also we are deleting questions so they will not repeat in the final report*/
    useEffect(() => {
        const enpoints = `${process.env.REACT_APP_API}/registrationdata/`;

        let deleteAnswers = `${process.env.REACT_APP_API}/answers/deleteAnswers/`;
        const fetchdata = async () => {
            await axios.get(`${enpoints}${id}`)
                .then(response => {
                    setourData(response.data['gender'].toLowerCase());
                    setname(response.data['name'])
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        const deletedata = async () => {
            await axios.delete(`${deleteAnswers}${id}`)
                .then(response => {
                    if (response.data.message === 'No') {
                        setFinish('No');

                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        fetchdata();
        deletedata();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let finishdata;
    finishdata = `${process.env.REACT_APP_API}/registrationdata/finishua/`;

    const handleSendEmail = () => {
        setseetheResult(t('countresult'));
        axios.get(`${finishdata}${id}`, { url: process.env.REACT_APP_SITE })
            .then(response => {
                setTimeout(() => {
                    window.location.href = `${process.env.REACT_APP_SITE}/finish/${id}`;
                }, 1000)

            })
            .catch((error) => {
                console.log(error);
            })

    }
    useEffect(() => {

        let questions = `${process.env.REACT_APP_API}/questions/`;
        let newURL = `${questions}${ourData}`;
        const getquestion = () => {
            axios.get(newURL)
                .then(response => {
                    console.log(`response: ${JSON.stringify(response.data.questions)}`);
                    setQuiz(JSON.stringify(response.data.questions));

                    setTimeout(() => {
                        setloading(false);
                    }, 5000);

                })
                .catch((error) => {
                    console.log(error);
                })
        }
        getquestion();
    }, [ourData]);

    const onCloseModal = () => {
        // setStep(2);
    }

    return (
        <>

            <section className="page__diagnose">
                {loading && <div className="loading">
                    <div id="loading-anim" className="img_loading" />
                </div>}
                <div className="container">
                    {step === 2 && Quiz.length !== 0 && Finish === '' && <Questions
                        finish={Finish}
                        personname={name}
                        loading={loading}
                        data={Quiz}
                        onAnswerUpdate={setAllanswers}
                        numberOfQuestions={JSON.parse(Quiz).length}
                        activeQuestion={activeQuestion}
                        onSetActiveQuestion={setActiveQuestion}
                        onSetStep={setStep}
                    ></Questions>}
                    {(step === 3 || (Finish !== '')) && <Questions
                        finish={Finish}
                        personname={name}
                        data={Quiz}
                        onAnswerUpdate={setAllanswers}
                        numberOfQuestions={JSON.parse(Quiz).length}
                        activeQuestion={activeQuestion}
                        onSetActiveQuestion={setActiveQuestion}
                        onSetStep={setStep}
                    ></Questions> && <ModalNoGo
                        show={showModal}
                        setShow={setShowModal}
                        onClose={onCloseModal}
                    >
                            <ModalHeader>
                                <h3>{t('greatfinish')}</h3>
                            </ModalHeader>
                            <ModalBody>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={handleSendEmail} style={{ margin: '0 auto' }}
                                    className='btn-grey'>{seetheResult}</Button>
                            </ModalFooter>
                        </ModalNoGo>
                    }

                </div>

            </section>
        </>
    );

};

export default FinalTest;
