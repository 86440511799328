import React from 'react'
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import classes from "./header.module.scss";


const ChangeLanguage = () => {
    const {t,i18n} = useTranslation();
    const navigate = useNavigate();
    const languages = ['uk', 'ru', 'eng'];
    
    
    const changeLanguage = (language)=>{
        i18n.changeLanguage(language);

        changeBrowserPath(language);
    };

    const changeBrowserPath = (language) => {
        const currentPath = window.location.pathname;

        const newPath = (language === 'uk')
            ? currentPath.replace(/^\/(uk|ru|eng)/, '') || '/'
            : `/${language}${currentPath.replace(/^\/(uk|ru|eng)/, '')}`
        ;

        navigate(newPath);
    }
    
    return (
        <>
            {languages.map((lang) =>
                i18n.language === lang ? (
                    <span key={lang} className={classes.active}>
                      {lang.toUpperCase()}
                    </span>
                ) : (
                    <a key={lang} onClick={() => changeLanguage(lang)}>
                        {lang.toUpperCase()}
                    </a>
                )
            )}
        </>
    )
};

export default ChangeLanguage