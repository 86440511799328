import React, {useEffect, useState} from 'react'
import {BiMenuAltRight} from 'react-icons/bi';
import {AiOutlineClose} from 'react-icons/ai';
import classes from "./header.module.scss";
import logo from './../assets/main_page/logo.svg'
import {Link} from "react-scroll";
import {Link as Linkto, useLocation, useParams} from "react-router-dom";
import Button from './buttons/Button';
import {useTranslation} from 'react-i18next';
import ChangeLanguage from './ChangeLanguage';

const Header = ({setShowModal}) => {
    const {pathname} = useLocation();
    const {id} = useParams();
    const IDData = useLocation();
    const IdWhatweNeed = IDData.search.slice(4);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [size, setSize] = useState({
        width: undefined,
        height: undefined,
    });
    const [lastScrollY, setLastScrollY] = useState(0);
    const {t, i18n} = useTranslation();
    const currentLang = i18n.language !== 'uk' ? `/${i18n.language}` :'';

    
    useEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY < lastScrollY && currentScrollY < 200) {
                setIsMenuOpen(false);
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);


    const OpenPayPal = () => {
        if (size.width < 991 && isMenuOpen) {
            setIsMenuOpen((prevState) => !prevState);
        }
        setShowModal(true);
    }
    const menuToggleHandler = (event) => {
        if(event.currentTarget.getAttribute('id') !== 'Homenav'){
            setIsMenuOpen((prevState) => !prevState);
        }
    };

    const lastPathElement = '/' + pathname.split('/').at(-1);
    const shouldShowLogoLink = ['/', '/quiz', '/calibration', '/camera', '/oferta', '/politica', '/instruction', '/payment'].includes(lastPathElement);
    const shouldShowLogoLinkAlt = pathname.indexOf('/instruction/') > -1 || pathname.indexOf('/camera/') || pathname.indexOf('/end/') > -1;

    const testPaths = [ '/quiz', '/calibration', '/camera'];
    const languages = ['', '/eng', '/ru'];
    const pathsToCheck = testPaths.flatMap(path => languages.map(lang => `${lang}${path}`));
    const shouldShowGoTestButton = pathsToCheck.includes(pathname);

    return (
        <header className={classes.header}>
            <div className={classes.header__content}>
                {shouldShowLogoLinkAlt || (shouldShowLogoLink && !id) ? (
                    <>
                        <Linkto reloadDocument to={currentLang + '/'}>
                            <div className={classes.header__logo}>
                                <img src={logo} alt="" className={classes.header__logo__image}/>
                                <h2 className={classes.header__logo__text}>BeSure</h2>
                            </div>
                        </Linkto>
                        {shouldShowGoTestButton && !IdWhatweNeed && pathname !== '/' &&
                            <Button onClick={() => setShowModal(true)}
                                // className={classes.TryNowHeader}
                                    id='HeaderButton'
                                    style={{margin: '0px', padding: '15px 35px'}}>{t('go_test')}</Button>}
                    </>
                ) : (
                    <>
                        <div className={classes.header__logo}>
                            <img src={logo} alt="" className={classes.header__logo__image}/>
                            <h2 className={classes.header__logo__text}>BeSure</h2>
                        </div>
                    </>
                )}

                {pathname === '/' &&
                    <Button onClick={() => setShowModal(true)} className={classes.TryNowHeader} id='HeaderButton'
                            style={{margin: '0px', padding: '15px 15px'}}>{t('go_test')}</Button>}
                {pathname === '/demo' && <Button onClick={() => setShowModal(true)} id='HeaderButton'
                                                 style={{margin: '0px', padding: '15px 45px'}}>{t('go_test')}</Button>}
                {/* <Linkto className={classes.TryNowHeader} to='/instruction'> */}

                { (pathname === '/' || pathname === '/ru/' || pathname === '/eng/') &&
                    <nav className={`${classes.header__content__nav} ${isMenuOpen ? classes.isMenu : ''}`}>
                        <ul>
                            <li>
                                <Link to="Home" spy={true} smooth={true} duration={500} id='Homenav'
                                      onClick={menuToggleHandler}>  {t("home")}</Link>
                            </li>
                            <li>
                                <Link to="HowWorks" spy={true} smooth={true} duration={500} id='ItWorksnav'
                                      onClick={menuToggleHandler}> {t("HOW")}</Link>
                            </li>
                            <li>
                                <Link to="ItWork" spy={true} smooth={true} duration={500} id='Flownav'
                                      onClick={menuToggleHandler}>{t("TODO")}</Link>
                            </li>
                            <li>
                                <Link to="FAQ" spy={true} smooth={true} duration={500} id='Faqnav'
                                      onClick={menuToggleHandler}>{t("FAQ")}</Link>
                            </li>
                            <li>
                                <Button id='BuyNownav' onClick={OpenPayPal}
                                        style={{margin: '0px', padding: '20px 55px'}}>{t("go_test")}</Button>
                            </li>
                            <li className={classes.lang}>
                                <ChangeLanguage/>
                            </li>
                        </ul>
                    </nav>}
                {pathname !== '/' && pathname !== '/ru/' && pathname !== '/eng/' &&
                    <nav className={`${classes.header__content__nav} ${isMenuOpen ? classes.isMenu : ''}`}>
                        <ul>
                            <li className={classes.lang}>
                                <ChangeLanguage/>
                            </li>
                        </ul>
                    </nav>}

                {(pathname === '/' || pathname === '/test' || pathname.startsWith('/result') || pathname === '/calibration' || pathname === '/camera') &&
                    <div className={classes.header__content__toggle}>
                        {isMenuOpen ?
                            <AiOutlineClose onClick={menuToggleHandler}/> :
                            <BiMenuAltRight onClick={menuToggleHandler}/>}
                    </div>}
            </div>
        </header>
    )
}

export default Header