import React from 'react'
import {useTranslation} from 'react-i18next';
import {Link as Linkto, useLocation} from "react-router-dom";

import Visa from "./../../assets/EndResult/VISA.png";
import Mastercard from "./../../assets/EndResult/MasterCard.png";
import './Footer.scss';

const Footer = () => {
    const {t, i18n} = useTranslation();
    
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path;
    };
    
    return (
        <footer className='footer'>
            <div className="container">
                <div className="footer__elements">
                    <ul className='footer__elements__list'>
                        <li>
                            {isActive('/politica') ? 
                                <span className="footer__elements__list__item active">{t('policy')}</span>
                                :
                                <Linkto to='politica' className="footer__elements__list__item">{t('policy')}</Linkto>
                            }
                        </li>
                        <li>
                            {isActive('/oferta') ?
                                <span className="footer__elements__list__item active">{t('oferta')}</span>
                                :
                                <Linkto to='oferta' className="footer__elements__list__item">{t('oferta')}</Linkto>
                            }
                        </li>
                        <li><img className='footer_image' src={Mastercard} alt="Mastercard"/></li>
                        <li><img className='footer_image' src={Visa} alt="VISA"/></li>
                    </ul>
                    <p className="footer__elements__copyright">Copyright @ 2025 BeSure</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer