import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Calibration.scss'
import buttonStyles from '../../components/buttons/Button.module.scss';




const Calibration = (props) => {
    const {t,i18n} = useTranslation();
    const [Show, setShow] = useState(false);
    const NextData = useLocation();
    const NextId = NextData.search.slice(4);
    const currentLang = i18n.language !== props.defaultLanguage ? `/${i18n.language}` :'';
    const link = `${currentLang}/camera/${NextId}`;
    const cameralink = `${currentLang}/camera`;


    const showButton = () => {
        setShow(true);
    }
    setTimeout(showButton, 25000);
    return (
        <>
            <Helmet>
                <script type="module" defer src="/heart.js"></script>
            </Helmet>

            <section className="calibration_block">
                <div className="container">
                    <div className="calibration_block__header">
                        <h2 className="calibration_block__header__title PADDING__CHANGE">{t('heart')}</h2>
                        <p className="calibration_block__header__description">{t('heart_description')}</p>
                    </div>
                    <p className='block__smalltitle'>{t('heart_text1')}</p>
                    <p className='block__smalltitle'>{t('heart_text2')}</p>
                    <div id="video-container">
                        <center>
                            <p id='waiting' className='block__smalltitle'>{t('heart_text3')}</p>
                            <video playsInline id="webcam" className='webcam_calibration' width="640" height="480"></video>
                            <canvas id="canvasit"></canvas>
                        </center>

                    </div>
                    {NextId.length > 0 ?
                        <>
                            <div className='calibration_block__buttons'>
                                <p id='element' className='block__smalltitle__red'>{t('heart_noface')}</p>

                                {Show && <Link reloadDocument to={link}>
                                    <span className={buttonStyles.button} id='finish'>{t('heart_nextbutton')}</span>
                                </Link>}
                            </div>
                        </>
                        :
                        <>
                            <div className='calibration_block__buttons'>
                                <p id='element' className='block__smalltitle__red'>{t('heart_nextbutton')}</p>
                                {Show && <Link reloadDocument to={cameralink}>
                                    <span className={buttonStyles.button} id='finish'>{t('heart_nextbutton')}</span>
                                </Link>}
                            </div>
                        </>
                    }
                </div>
            </section >
        </>
    )
}

export default Calibration