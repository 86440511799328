import React, {useEffect, useRef} from 'react'
import "./Popup.scss"
import Button from "../buttons/Button";

const Modal = ({show, setShow, children}) => {
    const modalRef = useRef();

    const clickCross = (e) => {
        setShow(false);
    };

    const closeModal = () => setShow(false);

    useEffect(() => {
        const clickOutsideContent = (e) => {
            if (modalRef.current && e.target === modalRef.current) {
                closeModal();
            }
        };
        window.addEventListener('click', clickOutsideContent);
        return () =>
            window.removeEventListener('click', clickOutsideContent);
        ;
    }, []);

    return <div
        className={`modal ${show ? 'active' : ''}`}>

        <div className="modal__content">
            <div className="modal__cross" onClick={clickCross}>×</div>
            {children}
        </div>
    </div>;
};

export default Modal;

export const ModalHeader = ({children}) => {
    return <>
        <div className="modal__header">
            {children}
        </div>
    </>
}

export const ModalBody = ({children}) => {
    return <div className="modal__body">
        {children}
    </div>
}

export const ModalFooter = ({children}) => {
    return <div className="modal__footer">
        {children}
    </div>
}

export const ModalNoGo = ({show, setShow, onClose = () => {}, children}) => {
    const modalRef = useRef();

    const closeModal = () => {
        setShow(false);
        onClose();
    }
    
    useEffect(() => {
        const clickOutsideContent = (e) => {
            if (modalRef.current && e.target === modalRef.current) {
                setShow(false);
            }
        };
        window.addEventListener('click', clickOutsideContent);
        return () => {
            window.removeEventListener('click', clickOutsideContent);
        };
    }, []);
    
    
    return <div ref={modalRef} className={`modal ${show ? 'active' : ''}`}>
        <div className="modal__content">
            <Button className="modal__cross" onClick={closeModal}>×</Button>
            {children}
        </div>
    </div>;
};