import React from 'react'
import firstImage from './../../assets/main_page/first.png'
import secondImage from './../../assets/main_page/second.png'
import thirdImage from './../../assets/main_page/third.png'
import fourthImage from './../../assets/main_page/fourth.png'
import './Benefits.scss'
import { useTranslation } from "react-i18next";

const Benefits = () => {
    const {t,i18n} = useTranslation();
    return (
        <section className="Benefit">
            <div className="container">
                <h2 className="Benefit__title">{t("why_title")}</h2>
                <div className='Benefit__list'>
                    <div className="Benefit__list__column">
                        <div className='Benefits__list__item'>
                            <img alt='' className='Benefits__list__item__image' src={firstImage} aria-hidden={true} role={"presentation"} />
                            <p className='Benefits__list__item__description'>{t("dostovernost")}</p>
                        </div>
                    </div>
                    <div className="Benefit__list__column">
                        <div className='Benefits__list__item'>
                            <img alt='' className='Benefits__list__item__image' src={secondImage} aria-hidden={true} role={"presentation"} />
                            <p className='Benefits__list__item__description'>{t("results")}</p>
                        </div>
                    </div>
                    <div className="Benefit__list__column">
                        <div className='Benefits__list__item'>
                            <img alt='' className='Benefits__list__item__image' src={thirdImage} aria-hidden={true} role={"presentation"} />
                            <p className='Benefits__list__item__description'>{t("whatto")}</p>
                        </div>
                    </div>
                    <div className="Benefit__list__column">
                        <div className='Benefits__list__item'>
                            <img alt='' className='Benefits__list__item__image' src={fourthImage} role={"presentation"} aria-hidden={true} />
                            <p className='Benefits__list__item__description'>{t("price")}</p>
                        </div>
                    </div>

                </div >
            </div >
        </section >

    )
}

export default Benefits