import React from 'react'
import "./Payment.scss";
import wayforpay1 from "./../../assets/EndResult/wayforpay1.png"

const Payment = () => {
    return (
        <section style={{ "minHeight": '90vh' }} className="block__itWorks">
            <div className="container">
                <div className="block__itWorks__header">
                    <h2 className="block__itWorks__header__title">Сторінка оплати</h2>
                    <p className="block__itWorks__header__description">Оплата здійснюється за допомогою платіжних систем Visa та MasterCard. Після сплати ви отримуєте повноцінні результати. Після цього послуга вважається виконаною.</p>
                    <img src={wayforpay1} alt="wayforpay" className='' />
                    <p className="block__itWorks__header__description">Вартість 699 грн</p>
                    <p className="block__itWorks__header__description">Продовжуючи, ви погоджуєтесь з <a href='/oferta'>Договіром оферти</a> та <a href='/politica'>Політикою конфіденційності</a></p>
                    <p className="block__itWorks__header__description"><strong>Політика повернення</strong></p>
                    <p className="block__itWorks__header__description">Ви можете знайти у договорі публічної оферти в параграфі: 10 «Політика повернення та відстрочення надання послуг».</p>
                    <p className="block__itWorks__header__description"><strong>Контактні дані:</strong> ФОП Алексанкін І.С., ІПН: 3007203998, Україна, м. Кривий Ріг, пр. Поштовий, 52, info@stimul.ua, тел: +380679273005.</p>
                </div>

            </div>
        </section>
    )
}

export default Payment