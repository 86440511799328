import React from 'react'
import Button from './../../components/buttons/Button'
import './Instruction.scss'
import star from './../../assets/test_page/stars.svg'
import arrow from './../../assets/test_page/arrow.svg'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


const Instruction = (props) => {
    let { id } = useParams();
    const {t,i18n} = useTranslation();
    const currentLang = i18n.language !== props.defaultLanguage ? `/${i18n.language}` :'';
    let toCalibration = `${currentLang}/calibration?id=${id}`;
    let URLdata = `${currentLang}/calibration`;
    return (
        <section className='instruction-block'>

            <div className="container">
                <div className="instruction-block__body">
                    <div className='instruction-block__header'>
                        <div className="top-content">
                            <div className="top-content__elements">
                                <p className='instruction-block__header__small-title'>{t('abovetitle')}</p>
                                <h1 className='instruction-block__header__title'>{t('instruction_title')}</h1>
                            </div>
                            <img alt='star' src={star} className="top-content__image" />
                        </div>

                        <p className='instruction-block__header__description'>{t('instruction_text1')}</p>
                        <p className='instruction-block__header__description'>{t('instruction_text2')}</p>
                        <div className='instruction-block__header__buttons'>
                            {id
                                ?
                                <>
                                    <Link reloadDocument to={toCalibration}>
                                        <Button className="button_margin" >{t('instruction_nextbutton')}</Button>
                                    </Link>
                                </>
                                :
                                <>
                                    <Link reloadDocument to={URLdata}>
                                        <Button className="button_margin" >{t('start_demonstration')}</Button>
                                    </Link>
                                </>}
                            <div className="buttons__image">
                                <img alt='arrow' src={arrow} className='instruction-block__header__buttons__arrow'></img>
                            </div>
                        </div>
                    </div>
                    <div className='instruction-block__photo'>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Instruction