import React, {useEffect} from 'react'
import {useLocation} from "react-router-dom";
import './Oferta.scss';

const Oferta = () => {
    const location = useLocation();
    
    useEffect(() => {
        if (location.pathname === '/oferta') {
            window.scrollTo(0, 0);
        }
    }, [location]);
    
    return (
        <>
            <div id="top"></div>
            <section id="ItWork" className="block_testing">
                <div className="container">
                    <div className="block_testing__header">
                        <h2 className="block_testing__header__title">Договір оферти</h2>
                    </div>
                    <div className="block_testing__main">
                        <h5>ЗАГАЛЬНІ ПОЛОЖЕННЯ</h5>
                        <div className='document_div'>
                            <p>1.1. Цей Публічний договір (далі – Оферта, договір) є офіційною пропозицією «Виконавця»
                                на сайті https://besure.stimul.ua, щодо надання Інформаційних послуг за допомогою
                                програмного забезпечення сайту: https://besure.stimul.ua (далі — Сайт) юридичним та/або
                                дієздатним фізичним особам (далі – Замовник) на наведених нижче умовах шляхом надання
                                права проходження онлайн опитувальника та подальшою можливістю перегляду результатів
                                тестування з можливістю їх збереження.</p>

                            <p>1.2. Прийняття (акцепт) даної Оферти означає повне та беззастережне прийняття Замовником
                                всіх умов без будь-яких винятків та/або обмежень та прирівнюється відповідно до ч. 2 ст.
                                642 Цивільного кодексу України (далі ЦК України) до укладання сторонами двостороннього
                                договору на умовах, викладених нижче в цій Оферті.</p>

                            <p>1.3. Даний Публічний договір на надання Інформаційних послуг (Оферта) вважається
                                укладеним (акцептованим) з моменту Замовником введення особистих даних на Сайті
                                Виконавця або надходження коштів Замовника на розрахунковий рахунок Виконавця.</p>

                            <p>1.4. Виконавець та Замовник надають взаємні гарантії своєї право- та дієздатності
                                необхідні для укладання та виконання цього Договору на надання Інформаційних послуг.</p>
                        </div>

                        <h5>ВИЗНАЧЕННЯ І ТЕРМІНИ</h5>
                        <div className='document_div'>
                            <p>2.1. В цілях цієї Оферти наведені нижче терміни використовуються в наступному
                                значенні:</p>
                            <p>Оферта &ndash; поточний громадський договір для надання інформаційних послуг.
                                Сайт &ndash; інтернет-сайт: https://besure.stimul.ua, який використовується Виконавцем
                                на правах власності. Інформаційні послуги &ndash; будь-які послуги Виконавця, види та
                                вартість яких визначена на Сайті, щодо надання обмеженого доступу Замовнику до Сайту
                                відповідно до умов Оферти для отримання інформаційних послуг Виконавця з надання за
                                плату онлайн тестів.</p>
                            <p>Акцепт Оферти - повне та беззастережне прийняття Оферти шляхом здійснення дій по 100%
                                попередній оплаті Інформаційної послуги.</p>
                            <p>Виконавець &ndash; сайт https://besure.stimul.ua в особі ФОП &laquo;Алексанкін
                                І.С.&raquo; або іншого суб'єкта господарювання, який має право надати Інформаційні
                                послуги Замовнику на умовах цієї Оферти. Замовник &ndash; особа, яка здійснила Акцепт
                                Оферти на викладених у ній умовах. Договір на надання інформаційних послуг
                                (далі &ndash; Договір) &ndash; договір між Замовником та Виконавцем на надання
                                Інформаційних послуг, який укладається за допомогою Акцепту цієї Оферти.</p>
                            <p>Інформаційна послуга - он-лайн тест на Сайті Виконавця, результати якого мають
                                рекомендаційний характер, і вимагають підтвердження досконаліших методик дослідження,
                                наприклад таких, як поліграф спільно з професійним психологом.</p>
                        </div>
                        <h5>ПРЕДМЕТ ОФЕРТИ</h5>
                        <div className='document_div'>
                            <p>3.1. Предметом цієї Оферти є відшкодування Замовнику Інформаційних послуг силами
                                Виконавця відповідно до умов цієї Оферти шляхом надання Інформаційних послуг в
                                інтерактивному (онлайн) форматі за винагороду, що виплачується Замовником Виконавцю.</p>
                            <p>3.2. Вартість кожного виду Інформаційної послуги встановлюється на Сайті Виконавця. Цей
                                договір надання Інформаційних послуг вважається укладеним з моменту надходження коштів
                                на розрахунковий рахунок Виконавця.</p>
                            <p>3.3. Платежі за цим договором здійснюються одним із способів, що пропонуються Замовнику,
                                у тому числі при натисканні кнопки &laquo;Оплатити&raquo; або &laquo;Отримати повний
                                звіт&raquo; які включають:</p>
                            <p>- Оплата електронними коштами;</p>
                            <p>- Оплата через платіжні термінали або інтернет-банкінг;</p>
                            <p>- Оплата на розрахунковий рахунок Виконавця через банк або банк-еквайр;</p>
                            <p>- Іншими способами за попереднім погодженням з Виконавцем.</p>
                        </div>


                        <h5>УМОВИ НАДАННЯ ІНФОРМАЦІЙНОЇ ПОСЛУГИ</h5>
                        <div className='document_div'>
                            <p>4.1. Виконавець надає обмежений доступ Замовнику до Інформаційної послуги, розміщеної на
                                Сайті у вигляді звіту за умови 100% передоплати цієї послуги.</p>
                            <p>4.2. Види та способи оплати кожного виду Інформаційної послуги зазначаються на Сайті.</p>
                            <p>4.3. Якщо протягом 2 робочих днів Замовник з тієї чи іншої причини не отримав доступу до
                                Інформаційної послуги, йому необхідно звернутися до служби підтримки Виконавця за
                                адресою: https://besure.stimul.ua</p>
                            <p>4.4. Виконавець зобов'язується надати Замовнику Інформаційну послугу шляхом організації
                                та надання доступу до онлайн-тесту, описаного на Сайті, або шляхом надання Замовнику
                                доступу до закритої зони Сайту протягом 2 робочих днів з моменту надходження коштів на
                                рахунок Виконавця, а також після можливого звернення Замовника щодо можливих ускладнень
                                з наданням доступу до звіту.</p>
                            <p>4.5. Ця Оферта має силу акту про надання послуг. Прийняття послуги провадиться без
                                підписання відповідного акту.</p>
                            <p>4.6. Після отримання звіту, повторний безкоштовний доступ до тестування на безкоштовній
                                основі Замовнику надається, але кожен наступний звіт оплачується окремо.</p>
                        </div>
                        <h5>ПРАВА ТА ОБОВ'ЯЗКИ ВИКОНАВЦЯ</h5>
                        <div className='document_div'>
                            <p>5.1. Виконавець зобов'язується надавати Замовнику цілодобовий доступ до звіту протягом
                                місяця.</p>
                            <p>5.2. Виконавець у процесі надання Інформаційної послуги бере на себе обов'язки з
                                технічного супроводу того, хто проходить тестування.</p>
                            <p>5.3. Виконавець несе відповідальність за зберігання та обробку персональних даних
                                Замовника, забезпечує конфіденційність цих даних у процесі їх обробки та використовує їх
                                виключно для якісного надання Інформаційної послуги Замовнику.</p>
                            <p>5.4. Виконавець має право змінювати будь-який елемент тесту та/або умови цієї Оферти в
                                односторонньому порядку без попереднього повідомлення Замовника.</p>
                            <p>5.5. Виконавець не несе відповідальності за дії Замовника щодо людини, яка проходила
                                онлайн-тест на Сайті Виконавця.</p>
                        </div>
                        <h5>ПРАВА ТА ОБОВ'ЯЗКИ ЗАМОВНИКА</h5>
                        <div className='document_div'>
                            <p>6.1. Замовник зобов'язаний надавати достовірну інформацію про себе в процесі внесення
                                своїх даних, необхідних для отримання звіту. Замовник відповідає за достовірність такої
                                інформації.</p>
                            <p>6.2. Замовник зобов'язується не відтворювати, не повторювати, не копіювати, не продавати,
                                а також не використовувати з будь-якою метою інформацію та матеріали, що стали йому
                                доступними у зв'язку з наданням Інформаційної послуги, за винятком їх особистого
                                використання.</p>

                            <p>6.3. Замовник зобов'язаний підтримувати у справному технічному стані обладнання та канали
                                зв'язку, що забезпечують доступ до Сайту. В
                                иконавець не несе відповідальності за ненадання (неякісне надання) Інформаційної послуги
                                з причин, що не залежать від Виконавця (в т.ч. через невиконання п. 7.1 цієї
                                Оферти).</p>
                            <p>6.4. Замовник користується всіма правами споживача відповідно до чинного законодавства
                                України, яке регулює відносини щодо оплатного надання послуг.</p>
                            <p>6.5. Замовник у разі надання йому послуги неналежної якості має право скористатися
                                правами, передбаченими ст. 8 Закону України &laquo;Про захист прав споживачів&raquo;,
                                виключно у випадках, коли порушення його прав сталося з вини Виконавця та за доведеністю
                                зазначеної обставини.</p>

                            <p>6.6. Усі претензії щодо якості наданої Інформаційної послуги повинні надсилатися
                                Замовником на адресу Виконавця шляхом подання заявки на електронну пошту: info@stimul.ua
                                Термін розгляду претензій Замовника Виконавцем становить 30 (тридцять) днів (у тому
                                числі претензій, що містять вимогу про повернення грошових коштів) з моменту надходження
                                претензії на адресу Виконавця, після закінчення якого Виконавцем приймається одне з
                                наступних рішень: 1) про незгоду з претензією та про відмову у поверненні коштів, або 2)
                                про згоду з претензією та задоволення вимоги про повернення коштів.</p>

                            <p>6.7. У разі прийняття рішення Виконавцем про повернення коштів, Кошти повертаються
                                Замовнику шляхом їх: зарахування на рахунок Замовника у платіжних системах Яндекс-Гроші,
                                WebMoney, QIWI, кредитну картку, особистий рахунок або інші реквізити, узгоджені
                                сторонами. Усі повернення коштів здійснюються за умови направлення Замовником на адресу
                                Виконавця заяви за формою, яка буде надіслана Замовнику листом електронною поштою.
                                Сторони беззастережно приймають, що остаточне рішення про спосіб повернення коштів
                                залишається в кожному конкретному випадку на розсуд Виконавця. У разі повернення коштів
                                на рахунок Замовника у банку або на рахунок Замовника у платіжній системі, заповнена
                                заява на повернення коштів за підписом Замовника за формою у сканованому електронному
                                вигляді надсилається електронною поштою Виконавцю (допускаються такі формати файлу: gif,
                                jpeg, pdf). Заява на повернення Грошових коштів повинна містити в обов'язковому порядку
                                відомості про рахунок Замовника, комерційну організацію (банк), де зберігається рахунок,
                                її адресу. У разі відсутності у заяві необхідних відомостей, необхідних для повернення
                                коштів, Виконавець не гарантує термінів.</p>

                            <p>6.8. Кошти зараховуються на рахунок у банку, зазначений Замовником, протягом 30
                                календарних днів з моменту, коли Виконавець отримав підписану та скановану заяву
                                Замовника за формою. Фінансовий документ, що підтверджує внесення коштів Виконавцем на
                                рахунок Замовника, є доказом виконання Виконавцем обов'язку щодо повернення коштів
                                Замовнику, що беззастережно приймається сторонами.</p>
                        </div>
                        <h5>ВІДПОВІДАЛЬНІСТЬ СТОРІН</h5>
                        <div className='document_div'>
                            <p>7.1 Виконавець і Замовник, враховуючи характер послуг, що надаються, зобов'язуються у
                                разі виникнення спорів та розбіжностей, пов'язаних з наданням Інформаційної послуги,
                                застосовувати досудовий порядок врегулювання спору. У разі неможливості врегулювання
                                спору у досудовому порядку, сторони мають право звернутися до Українського суду.</p>
                            <p>7.2 За невиконання або неналежне виконання зобов'язань по цій Оферті сторони несуть
                                відповідальність відповідно до законодавства України.</p>
                            <p>7.3. Виконавець не несе відповідальності за дії Замовника щодо людини, яка проходила
                                онлайн тест на Сайті Виконавця, та не несе відповідальності за інші дії Замовника, які
                                були наслідком проходження тесту.</p>
                        </div>
                        <h5>ГАРАНТІЇ ЯКОСТІ ІНФОРМАЦІЙНОЇ ПОСЛУГИ</h5>
                        <div className='document_div'>
                            <p>8.1. Замовник, приймаючи умови цієї Оферти, бере на себе повну відповідальність за всі
                                свої дії після отримання результатів тестування на Сайті Виконавця, результатів,
                                отриманих Замовником під час надання Інформаційної послуги.</p>
                            <p>8.2. Прийом до розгляду претензій Замовника з вимогою повернення коштів припиняється
                                після закінчення 5 (п'яти) календарних днів з моменту початку виконання тестування, що
                                беззастережно приймається сторонами. Інший спосіб продовження гарантованого терміну
                                повернення коштів неможливий і суперечить цій Оферті.</p>
                            <p>8.3. Умови гарантійних термінів надання Інформаційних послуг вказані на сайті
                                https://besure.stimul.ua</p>
                            <p>8.4. Претензії з вимогою щодо повернення коштів, внесених як оплата за виведення
                                результату тесту, що виникли не з вини Замовника, Виконавцем не розглядаються.</p>
                            <p>8.5. Претензії з вимогою про повернення коштів мають бути надіслані Виконавцю не пізніше,
                                ніж за добу до закінчення гарантійного строку. Інші претензії з вимогою про повернення
                                коштів Виконавцем не розглядатимуться.</p>
                        </div>
                        <h5>ФОРС МАЖОР</h5>
                        <div className='document_div'>
                            <p>9.1. Сторони звільняються від відповідальності за часткове або повне невиконання
                                зобов'язань за цим Договором, якщо це невиконання стало наслідком дії обставин
                                непереборної сили (форс-мажорних обставин), що виникли після укладення цього Договору та
                                унеможливили виконання зобов'язань відповідно до умов цього Договору. До таких обставин
                                зокрема, але не обмежуючись, належать надзвичайні ситуації техногенного, природного чи
                                екологічного характеру, аварії в системах електропостачання, руйнування цих систем,
                                спричинені, зокрема, землетрусами, повенями, ураганами тощо, тривала відсутність
                                електроенергії та інтернету з незалежних від сторін причин, військові дії, заколот,
                                страйк, масові заворушення та інші протиправні дії, повінь, пожежа, антитерористичні
                                операції, землетрус та інші стихійні лиха, війна, військові дії, неконтрольовані,
                                протиправні дії та акти вандалізму третіх осіб, революційні дії, громадські заворушення,
                                акти чи дії органів державного управління, прийняття законних чи підзаконних актів, які
                                прямо впливають на можливість виконання сторонами умов цього договору, та будь-які інші
                                надзвичайні обставини.</p>
                            <p>9.2. Сторони домовились, що у разі виникнення обставин, передбачених п. 10.1. Договору,
                                термін виконання зобов'язань за цим Договором відсувається на період часу, протягом
                                якого діяли ці обставини та їх наслідки.</p>
                            <p>9.3. Якщо форс-мажорні обставини та їх наслідки продовжують діяти понад шість місяців,
                                сторони у найкоротші терміни повинні провести переговори з метою виявлення прийнятних
                                для обох Сторін альтернативних способів виконання умов цього Договору та досягнення
                                відповідних письмових домовленостей.</p>
                        </div>
                        <h5>ІНШІ ПОЛОЖЕННЯ</h5>
                        <div className='document_div'>
                            <p>10.1. Замовник гарантує, що всі умови оферти йому зрозумілі і він приймає їх безумовно та
                                в повному обсязі, без будь-яких умов, вилучень та застережень.</p>
                            <p>10.2. У випадку, який не врегульований цим Договором, Сторони зобов'язуються керуватись
                                нормами, встановленими чинним законодавством України.</p>
                            <p>10.3. До цього договору застосовується законодавство України.</p>
                            <p>10.4. Сторони дають одна одній згоду на обробку та зберігання персональних даних, які
                                стають відомими їм, у зв'язку із укладенням цього Договору, у межах, у яких це необхідно
                                відповідно до вимог чинного законодавства України.</p>

                        </div>
                        <h5>КОНТАКТИ ВИКОНАВЦЯ</h5>
                        <div className='document_div'>
                            <p>Адреса: 50000, м. Кривий Ріг, пр. Поштовий, 52/412.</p>
                            <p>email: info@stimul.ua</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Oferta