import Button from "../buttons/Button";
import illustration from "./../../assets/main_page/illustartion_main.png";
import Camera from "./../../assets/main_page/Camera.svg";
import Cameragreen from "./../../assets/main_page/CameraGreen.svg";
import arrow from "./../../assets/main_page/arrow.svg";
import star from "./../../assets/main_page/stars.svg";
import Benefits from "./../Benefits/Benefits";

import "./Welcome.scss";
import { useTimer } from 'react-timer-hook';

import { ButtonIcon } from "../buttons/Button";
import * as faceapi from 'face-api.js';
import React, { useState, useRef, useEffect } from "react";
import { Bubble } from './../popup/Bubble/Bubble'
import { useTranslation } from "react-i18next";


function MyTimer({ expiryTimestamp }) {
  const {t,i18n} = useTranslation();
  const {
    minutes,
    hours,
    seconds,
    days,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });


  return (
    <>
      <text transform="matrix(1 0 0 1 304.859 164.272)" className="st3 st8 st9">{days}{t('days')} {hours}{t('hours')} {minutes}{t('minute')} {seconds}{t('seconds')}</text>
    </>

  );
}

const WelcomeBlock = ({ setShowModal }) => {
  const {t,i18n} = useTranslation();
  var todayDate = new Date();
  let time = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0, 23, 59, 59);
  const monthNames = [t("month1"), t("month2"), t("month3"), t("month4"), t("month5"), t("month6"),
  t("month7"), t("month8"), t("month9"), t("month10"), t("month11"), t("month12")
  ];

  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [captureVideo, setCaptureVideo] = useState(false);
  const [BubbleAppear, setBubbleAppear] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  let faceDetection;
  const videoRef = useRef();
  const canvasRef = useRef();
  let error = false;
  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + '/models';

      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68TinyNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]).then(setModelsLoaded(true));
    }
    loadModels();
  }, []);

  const startVideo = () => {
    setCaptureVideo(true);
    setBubbleAppear(false);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        const video = videoRef.current;
        if (video) {
          video.srcObject = stream;
          video.play()
          .then(() => {
            console.log("Video playing started.");
          })
          .catch((err) => {
            console.error("Error during video playback:", err);
          });
        }
        
      })
      .catch(err => {
        error = true;
        setBubbleAppear(true);
        console.error("error:", err);
      });
  }

  const handleVideoOnPlay = async () => {
    if (!videoRef.current) { // Ensure the video element exists
      console.error("Video reference is null or undefined. Cannot process input.");
      return;
    }
    
    
    faceDetection = setInterval(async () => {
    
      try {
        const displaySize = {
          width: 320,
          height: 240,
        }

        const useTinyModel = true;

        const detections = await faceapi
          .detectSingleFace(videoRef.current, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks(useTinyModel)
          .withFaceExpressions();

        if (!detections) {
          // console.warn("No face detected, skipping frame.");
          return; // Skip subsequent logic if no detection
        }
        
        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(videoRef.current);
        faceapi.matchDimensions(canvasRef.current, displaySize);
        new faceapi.TinyFaceDetectorOptions({
          inputSize: 256, // this line solves 'Box.constructor - expected box to be IBoundingBox | IRect, instead ...'
          scoreThreshold: 0.5,
        });
        const resizedDetections = faceapi.resizeResults(detections, displaySize);

        const canvasContext = canvasRef.current.getContext("2d");
        if (canvasContext) {
          canvasContext.clearRect(0, 0, 320, 240);
          faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
          faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);
          faceapi.draw.drawFaceExpressions(canvasRef.current, resizedDetections);
        }

      } catch (error) {
        console.error('DEBUG Error detecting face: ', error)
      }
    }, 100)
  }
  
  const closeWebcam = () => {
    if (videoRef.current) {
      const { srcObject } = videoRef.current;

      if (srcObject) {
        const tracks = srcObject.getTracks();
        tracks.forEach((track) => track.stop()); // Stop all tracks    
        videoRef.current.srcObject = null;
        setCaptureVideo(false);
      }
      if (!videoRef.current.paused) {
        videoRef.current.pause();
      }
    }

    if (!error){
      setBubbleAppear(false);
      setCaptureVideo(false);
      if (faceDetection) {
        clearInterval(faceDetection);
      }
    }
  }


  let xPosReport;
  let xPosPrice;
  let finishDate;
  let xPosFinishDate;
  switch (i18n.language) {
    case 'eng':
      xPosPrice = 307;
      xPosReport = -846;
      xPosFinishDate = 319.859;
      finishDate = `${monthNames[time.getMonth()]} ${time.getDate()}`;
      break;
    case 'uk':
      xPosPrice = 292.959;
      xPosReport = -837;
      xPosFinishDate = 327;
      finishDate = `${time.getDate()} ${monthNames[time.getMonth()]}`;
      break;
    case 'ru':
      xPosPrice = 292.959;
      xPosReport = -844;
      xPosFinishDate = 325.859;
      finishDate = `${time.getDate()} ${monthNames[time.getMonth()]}`;
      break;
    default:
      xPosPrice = 292.959;
      xPosReport = -837;
      xPosFinishDate = 319.859;
  }

  const isCameraEnabled = captureVideo && modelsLoaded;

  return (
    <section id="Home" className="welcome-block">
      <div className="container">
        <p className="welcome-block__header__small-title">
        {t("above_main")}
        </p>
        <div className="welcome-block__body">
          <div className="welcome-block__header">
            <h1 className="welcome-block__header__title">
            {t("main_title")}
            </h1>
            <p className="welcome-block__header__description">
            {t("under_main")}
            </p>
            <div className="welcome-block__header__buttons">
              {
                BubbleAppear ?
                    <Bubble/> :
                    <></>
              }

              <Button id='WelcomeButton' 
                      style={{marginBottom: '0px'}}
                      className="welcome-block__header__buttons__button"
                      onClick={() => setShowModal(true)}
              >
                {t("main_button")}
              </Button>
              
              {/*{*/}
              {/*  isCameraEnabled ?*/}
              {/*      <ButtonIcon id='Offcamera' */}
              {/*                  style={{marginBottom: '0px'}} */}
              {/*                  imageID="Offcamera" */}
              {/*                  className='btn-icon'*/}
              {/*                  onClick={closeWebcam} */}
              {/*                  icon={Cameragreen}*/}
              {/*      />*/}
              {/*      :*/}
              {/*      <ButtonIcon id='camera' */}
              {/*                  style={{marginBottom: '0px'}} */}
              {/*                  imageID="camera"*/}
              {/*                  className='btn-icon'*/}
              {/*                  onClick={startVideo} */}
              {/*                  icon={Camera}*/}
              {/*      />*/}
              {/*}*/}

              
              {/*<p className="welcome-block__header__buttons__text">*/}
              {/*  {isCameraEnabled ? t("main_camera_disable") : t("main_camera_enable")}*/}
              {/*</p>*/}
            </div>
          </div>
          <div className="welcome-block__icons">

            <img
                alt="arrow"
                    src={arrow}
                className="welcome-block__icon displaythis"
            ></img>
              <img alt="star" src={star} className="welcome-block__icon"></img>
          </div>
          <div className="welcome-block__photo">
            {
              captureVideo ?
                modelsLoaded ?
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                      <video playsInline ref={videoRef} onPlay={handleVideoOnPlay} style={{ width: '320px', height: '240px', borderRadius: '15px' }} />
                      <canvas ref={canvasRef} style={{ position: 'absolute' }} />
                    </div>
                  </div>
                  :
                  <img alt="illustration" src={illustration} className="photo"></img>
                :
                <img alt="illustration" src={illustration} className="photo"></img>
            }

            {!captureVideo && <svg className='svg_discount' cversion="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 166.2 165.9"
              style={{ enableBackground: "new 0 0 166.2 165.9" }}
              xmlSpace="preserve">
              <g id="price" transform="translate(-283.726 -31.149)">
                <g id="Group_1914">
                  <g id="Group_1913">
                    <path id="Path_1494" className="st0" d="M413.1,99c-16.5-33.8-57.3-47.8-91.1-31.3s-47.8,57.3-31.3,91.1s57.3,47.8,91.1,31.3
				c18-8.8,31.3-25.2,36.2-44.6C421.9,129.9,420.2,113.5,413.1,99z M348.1,71.9c2.6-2.6,6.8-2.6,9.3,0s2.6,6.8,0,9.3s-6.8,2.6-9.3,0
				c0,0,0,0,0,0C345.5,78.7,345.5,74.5,348.1,71.9C348.1,71.9,348.1,71.9,348.1,71.9z"/>
                    <path id="Path_1495" className="st1" d="M398.1,175.1c25.5-25.5,25.5-66.9,0-92.4s-66.9-25.5-92.4,0c-25.5,25.5-25.5,66.9,0,92.4
				C331.2,200.7,372.5,200.7,398.1,175.1z"/>
                  </g>
                  <path id="Path_1496" className="st2" d="M347.1,78.5c5.7-1.1,10.2-5.3,14.3-9.3c4.9-4.8,9.7-9.6,14.3-14.6c3.8-4.1,6.1-8.8,10.4-12.4
			c4-3.3,8.6-5.7,13.5-7.2c8.4-2.6,17.5-3.7,26.1-1.8c8.6,1.9,16.8,6.9,21.1,14.6c7.9,13.9-12.1,23.7-23.3,22.9
			c-9.9-0.7-18.9-5.7-27.5-10.5s-17.5-9.9-27.4-10.8s-20.9,3.5-24.6,12.7"/>
                </g>
                <g id="Group_1915">
                  <path id="Path_1497" className="st3" d="M418,145.5H285.8c-3.9-15.6-2.2-32,4.9-46.5h122.5C420.1,113.5,421.8,129.9,418,145.5z" />
                  <line id="Line_30" className="st4" x1="289.2" y1="102.2" x2="414.6" y2="102.2" />
                  <line id="Line_31" className="st4" x1="418.7" y1="142.4" x2="285" y2="142.4" />
                </g>
                <text transform={`matrix(1 0 0 1 ${xPosPrice} 135.502)`} className="st5 st6 st7">{t('price_real')}</text>
                <MyTimer expiryTimestamp={time} />
                {/* <text transform="matrix(1 0 0 1 304.859 164.272)" className="st3 st8 st9">Days to the end</text> */}
                <text transform={`matrix(1 0 0 1 ${xPosFinishDate} 176.189)`} className="st3 st8 st10">{t('upto')} {finishDate}</text>
                <g id="Group_1917" transform={`translate(${xPosReport} -125.362)`}>
                  <text transform="matrix(1 0 0 1 1173.9834 221)" className="st8 st95">{t('report')}</text>
                </g>
              </g>
            </svg>}

          </div>
        </div>
      </div>

      <Benefits />
    </section>

  );
};

export default WelcomeBlock;