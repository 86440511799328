import { useParams } from 'react-router-dom';
import './Button.module.scss';
import React, { useRef, useEffect } from "react";
const Button = props => {

    return <button type={props.type} ref={props.rref} id={props.id} style={props.style} form={props.form} className={props.className} onClick={props.onClick}>
        {props.children}
    </button>;
};

export default Button;

export const ButtonIcon = props => {
    return <button id={props.id} className={props.className} style={props.style} onClick={props.onClick} > 
        <img id={props.imageID} src={props.icon} className={props.classNameIcon} alt="icon" />
        {props.children}
    </button>;
};

export const PayPal = props => {
    const paypal = useRef();
    useEffect(() => {
        window.paypal
            .Buttons({
                createOrder: (data, actions, err) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                'description': "online cheating test",
                                'amount': {
                                    'currency_code': "USD",
                                    'value': 6.99,
                                },
                            },
                        ],
                    });
                },

                onApprove: function (data, actions) {
                    return actions.order.capture().then(function (orderData) {
                        window.location.href = `${process.env.REACT_APP_SITE}/result/${props.id}`;
                    });
                },

                onError: function (err) {
                    console.log(err);
                },
            })
            .render(paypal.current);
    }, []);

    return (
        <div>
            <div id={props.id} ref={paypal}></div>
        </div>
    );
};